.deviceinfo-outerdiv {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 5vh;
  flex-direction: column;
}

th,
td {
  padding-left: 2vmin;
  padding-right: 2vmin;
}
