.device-cards {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-evenly;
}

h2 {
  margin: 0;
  margin-top: 3vh;
}
p {
  margin: 0;
  margin-bottom: 3vh;
}
